/**
 * This is the class for a basic chart using Highcharts.
 * After creating an instance the draw function must be called.
 *
 * @class Chart
 * @constructor
 * @param {Object} chartContainer
 */
window.Chart = function (chartContainer) {
    this.chart = chartContainer;
    this.$el = $(chartContainer);
    this.loadedOptions = {};
    this.colors = {
        default: ['#7cb5ec', '#3a6381', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#8085e8', '#8d4653', '#91e8e1'],
        // 5 - 1 points, 0 points, index
        scatterColors: ['#ffd700', '#8aae1b', '#6baac5', '#00628d', '#ec7404','#b51621','#9c9e9f', '#e1e1e1', '#1a171b'],
        marketDetails: ['#9c9e9f'],
        time: ['#3a6381', '#939393'],
        ftBewertungTs: '#5fa1e6',
        quantilChanceAbsoluteTs: '#a2b868', //chance
        quantilRiskAbsoluteTs: '#e67e7c', //risk
        pricePerformanceColors: ['#ffdd00', '#ffee34', '#feff99', '#ffffdd', '#ffffff']
    };
    this.helpers = {
        percentageTooltip: function () {
            var date = new Date(this.x);
            var day = date.getDate();
            //day (incl leading zero) + month + year
            var dateString = (day.toString().length === 1 ? '0' + day : day) + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
            //round to 2 decimal points  + replace point by comma
            var value = (Math.round(this.y * 100) / 100).toString().replace(/\./g, ',');
            var seriesName = this.points[0].series.name;
            return '<b>' + dateString + '</b><br>'+ seriesName + ': ' + value;
        }
    };
    this.defaultOptions = {
        chart: {
            type: typeof $(this.chart).data('type') !== 'undefined' ? $(this.chart).data('type') : 'line',
            style: {
                fontFamily: 'arial, helvetica, sans-serif'
            }
        },
        title: '',
        credits: {
            text: '© Stiftung Warentest',
            href: 'http://www.test.de',
            position:{
                y: -5
            }
        },
        xAxis: {
            type: 'datetime',
            title: {
                enabled: false
            }
        },
        yAxis: {
            title: {
                enabled: false
            }
        },
        series: [],
        rangeSelector: {
            inputDateFormat: '%d.%m.%Y',
            inputEditDateFormat: '%d.%m.%Y',
            /**
             * transforms dateString from JqueryUI- to Highstock-"world"
             * @param dateString
             * @returns {number} UTC timestamp
             */
            inputDateParser: function (dateString) {
                var date = $.datepicker.parseDate('dd.mm.yy', dateString);
                return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
            }
        },
        tooltip: {
            dateTimeLabelFormats: {
                day: '%d.%m.%Y'
            }
        }
    };
};

Chart.prototype.setColors = function (options) {
    //set predefined colors
    if (typeof options.colors === 'string') {
        //if not in colors array use default colors
        options.colors = typeof this.colors[options.colors] !== 'undefined' ? this.colors[options.colors] : this.colors.default
    }
    for (var i = 0; i < options.series.length; i++) {
        var color = options.series[i].color ;
        //find color in palette
        if (typeof this.colors[color] !== 'undefined') {
            options.series[i].color = this.colors[color];
        }
    }
    return options;
};

Chart.prototype.setFunctions = function (options) {
    //set predefined functions
    if (typeof options.chart !== 'undefined' && typeof options.chart.events !== 'undefined') {
        if (typeof options.chart.events.load === 'string' && typeof this.helpers[options.chart.events.load] !== 'undefined') {
            options.chart.events.load = this.helpers[options.chart.events.load];
        }
    }
    if (typeof options.tooltip !== 'undefined' && typeof options.tooltip.pointFormatter === 'string') {
        options.tooltip.pointFormatter = this.helpers[options.tooltip.pointFormatter];
    }
    if (typeof options.tooltip !== 'undefined' && typeof options.tooltip.formatter === 'string') {
        options.tooltip.formatter = this.helpers[options.tooltip.formatter];
    }
    return options;
};

/**
 * Loads the chart data from the data attributes or an external JSON file
 * from the source given in the attribute "data-source"
 * @param: {Function} cb: callback function
 */
Chart.prototype.getData = function (cb) {
    var chart = this.chart;
    var values = $(chart).data('values');
    var jsonSource = $(chart).data('source');
    var callback = function (_this, json) {
        json = _this.setColors(json);
        json = _this.setFunctions(json);
        cb(json);
    };
    //get data from module itself
    if (typeof values !== 'undefined') {
        callback(this, this.lowerCaseKeys(values['Values'] || values));
    //get data from json file
    } else if (typeof jsonSource !== 'undefined') {
        (function (_this) {
            $.getJSON(jsonSource, function loadChartJson (json) {
                callback(_this, _this.lowerCaseKeys(json['Values']));
            }).error(function (error) {
                console.log('erorr while loading chart data: ', error);
                cb({series: [{name: "no data", data: []}]});
            });
        })(this);
    //no data
    } else {
        cb({series: [{name: "no data", data: []}]});
    }
};

/*
 * Makes the first letter of each key in obj to lower case.
 * Reason: Highcharts expects lower case but NX sends upper case.
 * @param: {Object} obj
 **/
Chart.prototype.lowerCaseKeys = function (obj) {
    var keys = Object.keys(obj);
    var cOld = '';
    var keyOld = '';
    var keyNew = '';
    for (var key in keys) {
        keyOld = keys[key];
        cOld = keyOld.substr(0,1);
        keyNew = cOld.toLowerCase() + keyOld.substr(1);
        if(cOld <= 'Z' && cOld >= 'A') {
            //is object
            if (typeof obj[keyOld] === 'object' && obj[keyOld] !== null) {
                obj[keyNew] = this.lowerCaseKeys(obj[keyOld]);
            //is value
            } else {
                obj[keyNew] = obj[keyOld];
            }
            //delete old stuff
            delete obj[keyOld];
        } else if (typeof obj[keyNew] === 'object' && obj[keyNew] !== null) {
            obj[keyOld] = this.lowerCaseKeys(obj[keyNew]);
        }
    }
    return obj;
};

/**
 * Draws the current chart using the loaded data and options
 */
Chart.prototype.draw = function (options, data) {
    var _this = this;
    var targetOptions = {};
    var d = function () {
        //extend default settings with the loaded ones
        $.extend(true, targetOptions ,_this.defaultOptions, _this.loadedOptions, options ? options : {});
        try {
            //use highstock to render
            if (targetOptions.isHighStock) {
                $(_this.chart).highcharts('StockChart', targetOptions, function(chart) {
                    // fixes a bug found in IE10: the chart didn't fit its (flexbox-)container. I guess reflowing is
                    // useful anyway (Niels Garve, niels.garve.publicispixelpark.de).
                    chart.reflow();
                });
            //use highcharts to render
            } else {
                $(_this.chart).highcharts(targetOptions, function(chart) {
                    // fixes a bug found in IE10: the chart didn't fit its (flexbox-)container. I guess reflowing is
                    // useful anyway (Niels Garve, niels.garve.publicispixelpark.de).
                    chart.reflow();
                });
            }
        } catch (error) {
            console.log('error while creating chart: ', error);
        }
    };

    //data is given
    if (typeof data !== 'undefined') {
        _this.loadedOptions = data;
        d();
    //get data before draw
    } else {
        this.getData(function (loadedData) {
            //set data
            _this.loadedOptions = loadedData;
            //draw chart
            d();
        });
    }
    return this;
};

Chart.prototype.addDatePicker = function (chart) {

    var lastDate = _getLastDate(chart);
    var firstDate = _getFirstDate(chart);
    var _this = this;

    function _getFirstDate (chart) {
        var earliestDate = chart.userOptions.series[0].data[0].x;
        for (var i = 1; i < chart.userOptions.series.length; i++) {
            var s = chart.userOptions.series[i];
            if (typeof s.data[0] !== 'undefined' && s.data[0][0] < earliestDate) {
                earliestDate = s.data[0][0];
            }
        }
        return earliestDate;
    }

    function _getLastDate (chart) {
        var firstSerie = chart.userOptions.series[0];
        return firstSerie.data[firstSerie.data.length-1].x;
    }

    setTimeout(function () { //use setTimeout, see fiddle by Torstein Hønsi the creator of highcharts http://jsfiddle.net/highcharts/cdFSQ/
        $('input.highcharts-range-selector', $(chart.container).parent())
            .attr('type', 'text')
            .datepicker({
                /**
                 * Adds CSS classes, synchronizes the two input fields by adjusting the minDate of the second datepicker
                 * @param input
                 * @param instance
                 */
                beforeShow: function (input, instance) {
                    $('#ui-datepicker-div').addClass('chart__datepicker');

                    // distinguish if it's the first or the last date input field by traversing the DOM tree
                    var isSecondInput = instance.input.prev().length !== 0 && instance.input.prev()[0].type === 'date';
                    var $firstInput = isSecondInput ? instance.input.prev() : instance.input,
                        $secondInput = $firstInput.next(),
                        startDate = $firstInput.datepicker('getDate'),
                        startDateNormalized = startDate.toDateString(),
                        startDateIndex = _this.dates.indexOf(startDateNormalized),
                        nextMonth = null;

                    if (startDateIndex !== -1) {
                        // can't get an "index out of range" because the last date is disabled for the first datepicker
                        // (see the beforeShowDay callback)
                        nextMonth = new Date(_this.dates[ startDateIndex + 1 ]);
                    } else {
                        // fallback
                        nextMonth = new Date(startDate.getTime());
                        nextMonth.setMonth(nextMonth.getMonth() + 1);
                    }

                    // redraw
                    $secondInput.datepicker('option', 'minDate', nextMonth);
                    $secondInput.trigger('change');

                    $firstInput.datepicker('setDate', startDate);
                    $firstInput.trigger('change');
                },
                /**
                 * invalidates days due to certain conditions
                 * @param day
                 * @returns {*}
                 */
                beforeShowDay: function (day) {
                    var $this = $(this),
                        $nextInput = $this.next(),
                        $prevInput = $this.prev(),
                        $otherInput = ($nextInput.length === 1) ? $nextInput : $prevInput;

                    // invalidate day if it equals the other input fields day
                    if ($otherInput && (day.getTime() === new Date($otherInput.datepicker('getDate')).getTime())) {
                        return [ false, '' ];
                    }

                    // given the first input field as context (checked via DOM tree traversion) invalidate day if it
                    // equals the last day. We're also leaving "JS-Date-world", so removing timezone-offset by using the
                    // toDateString() method
                    if ($nextInput.length === 1 && day.toDateString() === new Date(lastDate).toDateString()) {
                        return [ false, '' ];
                    }

                    // if no dates are given, then mark the day as "enabled"
                    if (!_this.dates) {
                        return [ true, '' ];
                    }

                    var dayNormalized = day.toDateString();
                    return [ _this.dates.indexOf(dayNormalized) >= 0, '' ];
                },
                /**
                 * synchronizes the two input fields in case the start date is out of range
                 * @param date
                 * @param instance
                 */
                onSelect: function (date, instance) {
                    // custom event for chart
                    $(_this.chart).trigger('onSelectDate', [ date, instance ]);

                    var $nextInput = instance.input.next();

                    // distinguish if it's the first or the last date input field by traversing the DOM tree
                    if ($nextInput.length === 1) {
                        var startDate = instance.input.datepicker('getDate'),
                            endDate = $nextInput.datepicker('getDate');

                        // is startDate out of range?
                        if (startDate.getTime() >= endDate.getTime()) {
                            // I see the use of timeout functions in many Highcharts examples and it works (threading)
                            setTimeout(function () {
                                // the 'show' method will trigger the "change" event: we need to wait until the second
                                // input field is set correctly (Highcharts can't draw back into the future ;))
                                $nextInput.datepicker('show');
                            }, 0);
                        } else {
                            instance.input.trigger('change');
                            instance.input.blur();
                        }
                    } else {
                        instance.input.trigger('change');
                        instance.input.blur();
                    }
                },
                maxDate: new Date(lastDate),
                minDate: new Date(firstDate),
                changeYear: true,
                changeMonth: true,
                monthNamesShort: [ 'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
                dateFormat: 'dd.mm.yy'
            })
            .attr('readonly', '')
            // fake an onInit function
            .each(function () {
                var $this = $(this);
            });
    }, 0);
};

/**
 * Extracts all timestamps from data.series, pushes them to _this.dates and converts them to a normalized date string
 * that can easily be compared with a given date in the same format. Jquery's datepicker will read _this.dates to
 * enable/disable days.
 * @author Niels Garve, <niels.garve.publicispixelpark.de>
 * @param data
 */
Chart.prototype.extractDates = function (data) {

    // param check
    if (typeof data.series === 'undefined') {
        return;
    }

    // remember
    this.dates = [];

    for (var i = 0; i < data.series.length; i++) {
        for (var j = 0; j < data.series[ i ].data.length; j++) {
            var xValue = data.series[ i ].data[ j ][ 0 ];

            if (typeof xValue === 'number' && this.dates.indexOf(xValue) === -1) {
                // add timezone offset to Noxums UTC timestamp and store it in a normalized way
                this.dates.push(new Date(xValue + new Date().getTimezoneOffset() * 60 * 1000).toDateString());
            }
        }
    }
};

/**
 * appends a date to the shortest data.series. It is the starting point of the iterative wealth calculations, where the
 * first value is set to 100%.
 * @param data
 * @returns {*}
 */
Chart.prototype.prependDate = function(data) {
    var shortestSeries = null,
        hasSameLength = false,
        shortestLength = Number.MAX_VALUE,
        seriesLength = data.series.length;

    for (var i = 0; i < seriesLength; i++) {
        if (data.series[ i ].data.length < shortestLength) {
            shortestLength = data.series[ i ].data.length;
            shortestSeries = data.series[ i ].data;
            hasSameLength = false;
        } else if (data.series[ i ].data.length === shortestLength) {
            hasSameLength = true;
        }
    }

    var firstDate = shortestSeries[ 0 ][ 0 ],
        lastDayOfPrevMonth = new Date(firstDate);

    lastDayOfPrevMonth.setDate(1);
    lastDayOfPrevMonth.setHours(-24 - lastDayOfPrevMonth.getTimezoneOffset() / 60);


    if (!hasSameLength) {
        shortestSeries.unshift([ lastDayOfPrevMonth.getTime(), 0 ]);
    } else {
        for (var i = 0; i < seriesLength; i++) {
            data.series[i].data.unshift([ lastDayOfPrevMonth.getTime(), 0 ]);
        };
    }

    return data;
};

/**
 * calculates wealth based on chart
 * @param chart
 */
Chart.prototype.recalcWealth = function (chart, isFirstLoad) {
    var seriesLength = chart.series.length,
        shortesLength = Number.MAX_VALUE,
        wealth;

    // find the length of the shortes chart
    for (var h = 0; h < seriesLength; h++) {
        if (chart.series[ h ].data.length < shortesLength) {
            shortesLength = chart.series[ h ].data.length;
        }
    }

    // each serie in chart.series
    for (var i = 0; i < seriesLength; i++) {

        var serie = chart.series[ i ];
        var isNavigator = serie.name.indexOf('Navigator') >= 0;

        // skip navigator
        if (isNavigator && !isFirstLoad) {
            continue;
        }

        var offset = serie.data.length - shortesLength,
            cashedSeries = this.cashedData[ i ],
            from = serie.cropStart,
            to = serie.cropEnd;

        //cropEnd could be undefined, use data length instead
        if (typeof to === 'undefined') {
            to = serie.data.length;
        }

        // if one series starts in between
        if (serie.data.length - serie.cropStart >= shortesLength) {
            from = offset;
        }

        // serie.cropStart sometimes lays outside the chart. Highstock needs to draw the line "into the unseen"
        if (serie.data[ from ].plotX < 0) {
            from += 1;
        }

        //reset for the navigator calculation
        if (isNavigator && isFirstLoad) {
            from = 0;
            //the navigator use always the first serie
            cashedSeries = this.cashedData[0];
        }

        // set starting point of the wealth calculation
        serie.data[ from ].update(100, false);

        // each point in serie upwards
        for (var j = from + 1; j < to; j++) {
            // calculate wealth based on cashed values
            wealth = serie.data[ j - 1 ].y * (1 + cashedSeries.data[ j ][ 1 ]);
            serie.data[ j ].update(wealth, false);
        }

        // each point in serie downwards
        for (var k = from - 1; k >= serie.cropStart; k--) {
            wealth = serie.data[ k + 1 ].y / (1 + cashedSeries.data[ k + 1 ][ 1 ]);
            serie.data[ k ].update(wealth, false);
        }
    }
};

Chart.prototype.destroy = function () {
    this.$el.highcharts().destroy();
};

//extend cropData function and add some more information to cropped series
// (function (cropData) {
//     //overwrite origin
//     Highcharts.Series.prototype.cropData = function (xData, yData, min, max) {
//         //call origin
//         var r = cropData(xData, yData, min, max);
//         //add info to series
//         this.cropEnd = r.end;
//         //retur original result
//         return r;
//     };
// })(Highcharts.Series.prototype.cropData);

/**
 * extends drawPoints for column-charts by adding the maxPointWidth attribute.
 * @see http://stackoverflow.com/questions/5968595/maximum-bar-width-in-highcharts-column-charts
 */
(function (H) {
    var each = H.each;
    H.wrap(H.seriesTypes.column.prototype, 'drawPoints', function (proceed) {
        var series = this;
        if (series.data.length > 0) {
            var width = series.barW > series.options.maxPointWidth ? series.options.maxPointWidth : series.barW;
            each(this.data, function (point) {
                point.shapeArgs.x += (point.shapeArgs.width - width) / 2;
                point.shapeArgs.width = width;
            });
        }
        proceed.call(this);
    })
})(Highcharts);

(function ($) {
    //create a jquery plugin
    $.fn.chart = function () {
        return this.each(function () {
            var chart;
            var self = this;
            /**
             * init chart on desktop devices
             */
            var initDesktop = function () {
                chart = new Chart(self);
                chart.draw();
            };

            if (!hasRwdSwitch()) {
                initDesktop();
                return;
            } // else

            // media queries
            enquire.register('screen and (max-width: 767px)', {
                match: function () {
                    if (chart) {
                        chart.destroy();
                    }

                    $(self)
                        .append('<a class="chart__open-chart btn btn-primary" href="#"><i class="icon icon-chart"></i>Chart anzeigen</a>')
                        .find('.chart__open-chart')
                        .click(function (e) {
                            e.preventDefault();

                            chart = new Chart(self);
                            // @todo the following options object makes the x axis become a time axis for all default charts
                            chart.draw({
                                xAxis: {
                                    range: 6 * 30 * 24 * 3600 * 1000 // six months, see: http://api.highcharts.com/highstock#xAxis.range
                                }
                            });
                        });
                }
            });

            enquire.register('screen and (min-width: 768px)', {
                match: initDesktop
            });
        });
    };

    $(function () {
        //set some global options
        Highcharts.setOptions({
            lang: {
                loading: 'lade...',
                decimalPoint: ',',
                thousandsSeparator: '.',
                months: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
                shortMonths: [ 'Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
                rangeSelectorFrom: "Von",
                rangeSelectorTo: "Bis",
                rangeSelectorZoom: "Zoom",
                resetZoom: "Zoom zurücksetzen",
                weekdays: [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ]
            }
        });

        $(
            '.chart[data-type="line"],' +
            '.chart[data-type="bar"],' +
            '.chart[data-type="area"],' +
            '.chart[data-type="column"]'
        ).chart();
    });
})(jQuery);
