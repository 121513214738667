window.StackedColumnChart = function (chart) {
    Chart.call(this, chart);
    this.newOptions = {
        chart: {
            type: 'column',
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                dataLabels: {
                    //shorten label if it's too long 
                    format: null, // need null here otherwise overrides the formatter function see STIWA-1927
                    formatter: function () {
                        var label = this.point.name;
                        var maxLength = 30;
                        if (label.length >= maxLength) {
                            label = label.substring(0,maxLength) + '...';
                        }
                        return label;
                    }
                }
            },
            column: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'normal'
                    }
                }
            }
        }
    };
    var columnStackedColors = [
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#6b8aa1'],
                [0.09, '#6b8aa1'],
                [0.1, '#376280'],
                [1, '#6a8aa1']
            ]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#a2b868'],
                [0.09, '#a2b868'],
                [0.1, '#829f33'],
                [1, '#a0b664']
            ]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#f8c853'],
                [0.09, '#f8c853'],
                [0.1, '#f5b619'],
                [1, '#f8c74f']
            ]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#78a7b9'],
                [0.09, '#78a7b9'],
                [0.1, '#4988a0'],
                [1, '#86afc0']
            ]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#f2a45c'],
                [0.09, '#f2a45c'],
                [0.1, '#ed8522'],
                [1, '#f2a257']
            ]
        },
        {
            linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            stops: [
                [0, '#bc5c5e'],
                [0.09, '#bc5c5e'],
                [0.1, '#a62527'],
                [1, '#bb5759']
            ]
        }
    ];
    //for list below stacked column chart
    var addColorToList = function () {
        var chart = this;
        //list of additional items
        var $list = $(chart.container).closest('.chart').find('+ .js-fondschart-more-values');

        //already initialized
        if ($list.hasClass('js-has-colors')) {
            return;
        }

        //color list
        var colors = columnStackedColors;
        var $listElements = $list.find('li');
        //for each item
        $listElements.each(function (index, value) {
            //index of related item in column
            var itemIndex = chart.series.length - $listElements.length + index;
            //color of item
            var currentColor = colors[itemIndex % colors.length].stops[2][1];
            //set color as square
            $(value).prepend('<span class="has-color" style="background:'+currentColor+';"></span>');
        });

        //remember init-state
        $list.addClass('js-has-colors');
    };
    //add special colors to this chart
    this.colors.columnStacked = columnStackedColors;
    //add event listener for load event
    this.helpers.addColorToList = addColorToList;  
};

//extend chart
StackedColumnChart.prototype = Object.create(Chart.prototype);
StackedColumnChart.prototype.constructor = StackedColumnChart;
StackedColumnChart.prototype.draw = function (options) {
    var _this = this;
    //call inherit getData() function
    this.getData(function (data) {
        data = _this.makeAllPositive(data);
        Chart.prototype.draw.call(_this, options, data);
    });    

    return this;
};

/**
* see bepro #6984: the chart isn't drawed 
* correctly if one value is negative
*/
StackedColumnChart.prototype.makeAllPositive = function (data) {
    for (var i = 0; i < data.series.length; i++) {
        for (var j = 0; j < data.series[i].data.length; j++) {
            data.series[i].data[j][1] = Math.abs(data.series[i].data[j][1]);
        };
    };
    return data;
}

//create a jquery plugin
$.fn.stackedColumnChart = function () {
    return this.each(function () {
        var chart;
        var $fondschartMore = $(this).find('+ .js-fondschart-more-values, ~ .js-fondschart-more');
        var self = this;
        /**
         * init chart on desktop devices
         */
        var initDesktop = function () {
            chart = new StackedColumnChart(self);
            chart.draw(chart.newOptions);
            $fondschartMore.show();
        };

        if (!hasRwdSwitch()) {
            initDesktop();
            return;
        } // else

        // media queries
        enquire.register('screen and (max-width: 767px)', {
            match: function () {
                if (chart) {
                    chart.destroy();
                }

                $fondschartMore.hide();

                $(self)
                    .append('<a class="chart__open-chart btn btn-primary" href="#"><i class="icon icon-chart"></i>Chart anzeigen</a>')
                    .find('.chart__open-chart')
                    .click(function (e) {
                        e.preventDefault();
                        chart = new StackedColumnChart(self);
                        chart.draw(chart.newOptions);
                        $fondschartMore.show();
                    });
            }
        });

        enquire.register('screen and (min-width: 768px)', {
            match: initDesktop
        });
    });
};

//call for each chart
$(function () {
    $('.chart[data-type="stackedcolumn"]').stackedColumnChart();
});
