/**
 * This is the class for a fonds performance chart using Highcharts.
 * It extends the basic Chart class with some defined calculations.
 * After creating an instance the draw function must be called.
 *
 * @class FondsPerformanceChart
 * @constructor
 * @extends Chart
 * @param {Object} chartContainer
 */
window.FondsPerformanceChart = function (chartContainer) {
    //call super constructor
    Chart.call(this, chartContainer);
    //reference
    var _this = this;

    /**
     * debounces the tracking of the navigator
     * @type {Function}
     */
    var trackNavigator = $.debounce(function () {
        window.Webtracking.send('fonds.einzelprodukt.grafik.wertentwicklung.zoomkalender');
    }, 300);

    // track user on selecting a date via datepicker using the custom event "onSelectDate"
    $(chartContainer).on('onSelectDate', function () {
        window.Webtracking.send('fonds.einzelprodukt.grafik.wertentwicklung.kalender');
    });

    //specific options
    this.newOptions = {
        chart: {
            type: 'line',
            events: {
                load: function () {
                    _this.recalcWealth(this, true);
                    _this.addDatePicker(this);
                    this.redraw();
                }
            },
            panning: false,
            zoomType: false,
            pinchType: false //BEPRO-7983: scroll on mobile (iOS) http://api.highcharts.com/highcharts#chart.pinchType
        },
        colors: ['#7cb5ec', '#434348', '#a1a1a3'],
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f}%</b><br/>',
            shared: true,
            split: false
        },
        plotOptions: {
            line: {
                marker: {
                    enabled: true,
                    radius: 3
                }
            },
            series: {
                tooltip: {
                    followTouchMove: false
                }
            }
        },
        xAxis: {
            events: {
                afterSetExtremes: function (e) {
                    if (e.trigger === 'navigator' || e.trigger === 'rangeSelectorButton' || e.trigger === 'rangeSelectorInput') {
                        _this.recalcWealth(e.target.chart);
                        e.target.chart.redraw();
                    }

                    // web tracking
                    if (e.trigger === 'navigator') {
                        trackNavigator();
                    }

                    if (e.trigger === 'rangeSelectorButton') {
                        window.Webtracking.send('fonds.einzelprodukt.grafik.wertentwicklung.zoomkalender');
                    }
                }
            },
            minRange: 28 * 24 * 60 * 60 * 1000 // one month [ms]
        },
        yAxis: {
            offset: 35,
            labels: {
                formatter: function() {
                    return this.value + '%';
                }
            },
            plotLines: [{
                color: '#999',
                dashStyle: 'dash',
                value: 100,
                width: 2
            }]
        },
        rangeSelector: {
            inputBoxBorderColor: '#ccc',
            inputBoxWidth: 94,
            inputStyle: {
                color: '#333'
            },
            inputPosition: {
              x : -35,
              y : 0
            },
            buttons: [
                {
                    type: 'year',
                    count: 1,
                    text: '1 Jahr'
                },
                {
                    type: 'year',
                    count: 3,
                    text: '3 Jahre'
                },
                {
                    type: 'year',
                    count: 5,
                    text: '5 Jahre'
                }
            ],
            buttonTheme: {
                width: 60
            },
            selected: 0,
            inputEnabled: false
        },
        isHighStock: true,
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        }
    };

    this.desktopOptions = $.extend(true, {}, this.newOptions, {
        navigator: {
            enabled: true,
            adaptToUpdatedData: false // else: the button "10 Jahre" doesn't work
        },
        rangeSelector: {
            buttons: [
                {}, {}, {},
                {
                    type: 'year',
                    count: 10,
                    text: '10 Jahre'
                }
            ],
            selected: 2,
            inputEnabled: true
        }
    });
};

//extend super class chart
FondsPerformanceChart.prototype = Object.create(Chart.prototype);
FondsPerformanceChart.prototype.constructor = FondsPerformanceChart;
FondsPerformanceChart.prototype.draw = function (options) {
    var _this = this;
    //call inherit getData() function
    this.getData(function (data) {

        _this.prependDate(data);
        _this.cashedData = $.extend(true, [], data.series);
        _this.extractDates(data);
        _this.addSeriesBeginningLines(options, data);

        //call super draw() function
        Chart.prototype.draw.call(_this, options, data);
    });
    return this;
};

/**
 * add a plot line to the beginning of each serie
 * @param: chart
 */
FondsPerformanceChart.prototype.addSeriesBeginningLines = function (options, data) {
    var plotLines = [];
    var sameLength = true;
    for (var i = 0; i < data.series.length; i++) {
        //replace label
        var label = data.series[i].name.indexOf("Referenzindex") >= 0 ? "Start Index": "Start Fonds";
        //define plot line
        plotLines.push({
            color: options.colors[i],
            width: 1,
            dashStyle: 'Dash',
            label: {
              text: label,
              verticalAlign: 'top',
              style: {
                color: options.colors[i]
              }
            },
            value: data.series[i].data[0][0]
        });
        if (sameLength && i > 0) {
            sameLength = data.series[i-1].data.length === data.series[i].data.length;
        }
    };
    //add plot lines to options
    //only if the series have different length
    if (!sameLength) options.xAxis.plotLines = plotLines;
};

//create a jquery plugin
$.fn.fondsPerformanceChart = function () {
    return this.each(function () {
        var chart;
        var self = this;
        /**
         * init chart on desktop devices
         */
        var initDesktop = function () {
            chart = new FondsPerformanceChart(self);
            chart.draw(chart.desktopOptions);
        };

        if (!hasRwdSwitch()) {
            initDesktop();
            return;
        } // else

        // media queries
        enquire.register('screen and (max-width: 767px)', {
            match: function () {
                if (chart) {
                    chart.destroy();
                }

                $(self)
                    .append('<a class="chart__open-chart btn btn-primary" href="#"><i class="icon icon-chart"></i>Chart anzeigen</a>')
                    .find('.chart__open-chart')
                    .click(function (e) {
                        e.preventDefault();
                        chart = new FondsPerformanceChart(self);
                        chart.draw(chart.newOptions);
                    });
            }
        });

        enquire.register('screen and (min-width: 768px)', {
            match: initDesktop
        });
    });
};

//call for each chart
$(function () {
    $('.chart[data-type="fondsperformance"]').fondsPerformanceChart();
});
