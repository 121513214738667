/**
 * This is the class for a scatter plot chart using Highcharts.
 * It extends the basic Chart class with some defined event listeners.
 * After creating an instance the draw function must be called.
 *
 * @class ScatterPlotChart
 * @constructor
 * @extends Chart
 * @param {Object} chart
 */

window.ScatterPlotChart = function (chart) {

    //call super constructor
    Chart.call(this, chart);

    var hideTooltip;

    //add tooltip to current fonds of this page
    var showCurrentFonds = function (chart) {
        //show tooltip of current fonds
        for (var i = 0; i < chart.series.length; i++) {
            if(!chart.series[i].visible) {
                continue;
            }
            for (var j = 0; j < chart.series[i].data.length; j++) {
                if (chart.series[i].data[j].showTooltip) {
                    chart.tooltip.refresh(chart.series[i].data[j]);
                    break;
                }
            }

        }
    };

    //find url of clicked point and refer to it
    var showFondsOverlay = function (point) {
        var pointIndex = point.index;
        var seriesIndex = point.series.index;
        var link = point.series.chart.userOptions.series[seriesIndex].data[pointIndex].link;
        if (link && typeof link.view === 'string') {
            $('<div class="fondsactions__trigger"><span href="#" data-dylo="load" data-dylo-mode="append" data-dylo-src="'+link.view+'"></span></div>')
                .appendTo('body')
                .find('span')
                .dylo();
        }
    };

    /**
     * @todo
     * @author Niels Garve, <niels.garve.publicispixelpark.de>
     * @param chart
     * @param active
     * @param options
     */
    var toggleManagedFonds = function (chart, active, options) {
        var series,point,radius;
        for (var i = 0; i < chart.series.length; i++) {

            series = chart.series[i];

            // @todo needed? what does it prevent?
            if( series.userOptions.marker && series.userOptions.marker.symbol === 'triangle') {
                continue;
            }

            for (var j = 0; j < series.data.length; j++) {
                //current point
                point = series.data[j];

                //toggle only related points
                if (active && !point.isActive || !active && point.isActive) {
                    continue;
                }

                /*
                 * correct radius: Highstock seems to set the radius to zero when disabling Points. Manually correcting
                 * it here.
                 */
                if (point.id === 'current' && point.marker.lineWidth > 1) {
                    // case: "Aktueller Fonds" is enabled
                    radius = 10;
                } else {
                    radius = point.marker.radius || chart.options.plotOptions.scatter.marker.radius
                }

                //set
                point.update({
                    marker: $.extend({}, chart.options.plotOptions.scatter.marker, point.marker, {
                        enabled: point.marker.enabled === false,
                        radius: radius,
                        states: {
                            hover: {
                                enabled: (point.marker.states) ? !point.marker.states.hover.enabled : false
                            }
                        }
                    }),
                    // remember display state for tooltip enabling/disabling
                    isHidden: !point.isHidden
                }, false);
            }
        }
        //redraw once
        chart.redraw();

        options.success();
    };

    /**
     * remembers the marker-options of the current fonds. Default: "bigger" marker
     * @author Niels Garve, <niels.garve.publicispixelpark.de>
     * @type {{radius: number, lineWidth: number, states: {hover: {radius: number, lineWidth: number}}}}
     */
    var currentFondsMarkerOpts = {
        radius: 10,
        lineWidth: 3,
        lineColor: '#f0f0f0',
        states: {
            hover: {
                radius: 12,
                lineWidth: 4,
                lineColor: '#f0f0f0'
            }
        }
    };

    /**
     * @todo
     * @author Niels Garve, <niels.garve.publicispixelpark.de>
     * @param chart
     * @param options
     */
    function toggleCurrentFonds(chart, options) {
        var currentFondsPoint = chart.get('current');

        if(currentFondsPoint === null || currentFondsPoint.marker.enabled === false) {
            options.error();
            return;
        }

        var tmpMarkerOptions = $.extend({}, chart.options.plotOptions.scatter.marker, currentFondsPoint.marker),
            newMarkerOptions = $.extend({}, tmpMarkerOptions, currentFondsMarkerOpts);

        currentFondsPoint.update({ marker: newMarkerOptions });

        currentFondsMarkerOpts = tmpMarkerOptions;

        options.success();
    }

    /**
     * @todo
     * @author Niels Garve, <niels.garve.publicispixelpark.de>
     * @param chart
     */
    var addAdditionalLinks = function (chart) {

        if(!chart.legend.options.enabled) {
            return;
        }

        $('<ul/>')
            .addClass('chart__scattertoggle')
            .append('<li><a class="chart__scattertoggle__active" href="#">Aktiv gemanagter Fonds</a></li>')
            .find('.chart__scattertoggle__active')
            .on('click', function (evt) {
                evt.preventDefault();
                var $this = $(this);

                toggleManagedFonds(chart, true, {
                    success: function () {
                        // update legend
                        $this.toggleClass('is-disabled');
                    }
                });
            })
            .end()
            .append('<li><a class="chart__scattertoggle__not" href="#">Indexfonds / ETF / Index</a></li></ul>')
            .find('.chart__scattertoggle__not')
            .on('click', function (evt) {
                evt.preventDefault();
                var $this = $(this);

                toggleManagedFonds(chart, false, {
                    success: function () {
                        // update legend
                        $this.toggleClass('is-disabled');
                    }
                });
            })
            .end()
            .appendTo($(chart.container).closest('.chart'));

            //prepend current fonds toggle to scatter plot
            //only if "current" is set
            if(chart.get('current') !== null) {
                $(chart.container).closest('.chart')
                    .prepend('<a class="chart__currentfonds btn btn-secondary btn-small" href="#">Aktuellen Fonds / Index hervorheben</a>')
                    .find('.chart__currentfonds')
                    .on('click', function (evt) {
                        evt.preventDefault();
                        var $this = $(this);
                        toggleCurrentFonds(chart, {
                            success: function () {
                                // update legend
                                $this.toggleClass('is-disabled');
                            },
                            error: function () {
                                alert('Aktueller Fonds ist ausgeblendet.');
                            }
                        });
                    });
            }

    };

    this.newOptions = {
        chart: {
            height: 400,
            type: 'scatter',
            events: {
                load: function () {
                    addAdditionalLinks(this);
                }
            },
            zoomType: 'xy'
        },
        xAxis: {
            events: {
                setExtremes: function (event) {
                    // web tracking
                    if (event.min != null) {
                        // When an axis is zoomed all the way out from the "Reset zoom" button, event.min and event.max are null
                        window.Webtracking.send('fonds.einzelprodukt.grafik.chance-risiko.zoom');
                    } else {
                        window.Webtracking.send('fonds.einzelprodukt.grafik.chance-risiko.zoom_zuruecksetzen');
                    }
                }
            }
        },
        yAxis: {
            min: 0 // fixes incorrect zooming, default is 0 (see the processData function)
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 6
                },
                point: {
                    events: {
                        click: function () {
                            showFondsOverlay(this);

                            // web tracking
                            window.Webtracking.send('fonds.einzelprodukt.grafik.chance-risiko.produktmenu');
                        },
                        //show that the points are clickable
                        mouseOver: function () {
                            $(this.series.chart.container).closest('.chart').addClass('has-pointer');
                        },
                        mouseOut: function () {
                            $(this.series.chart.container).closest('.chart').removeClass('has-pointer');
                        }
                    }
                },
                events: {
                    legendItemClick: function (e) {
                        // web tracking
                        if (!this.visible) {
                            window.Webtracking.send('fonds.einzelprodukt.grafik.chance-risiko.bewertung_an');
                        } else {
                            window.Webtracking.send('fonds.einzelprodukt.grafik.chance-risiko.bewertung_aus');
                        }
                    }
                }
            },
            series: {
                stickyTracking: false
            }
        },
        tooltip : {
            /**
             * enables/disables the tooltip depending on the points display state
             * @param tooltip
             * @returns {*}
             */
            formatter: function (tooltip) {
                if (this.point.isHidden) {
                    return false;
                } else {
                    return Highcharts.Tooltip.prototype.defaultFormatter.apply(this, arguments);
                }
            }
        },
        legend: {
            title: {
                text: 'FT-Bewertung:'
            },
            itemMarginTop: 5
        }
    };
};

//extend chart
ScatterPlotChart.prototype = Object.create(Chart.prototype);
ScatterPlotChart.prototype.constructor = ScatterPlotChart;

/**
 * This function extends the super class draw function with some error handling
 *
 * @return {Object} chart
 */
ScatterPlotChart.prototype.draw = function (options) {
    var _this = this;
    //call inherit getData() function
    this.getData(function (data) {
        //do calculation before
        data.series = _this.processData(data.series, options);
        //call super draw() function
        Chart.prototype.draw.call(_this, options, data);
    });
    return this;
};

/**
 * This function handles undefined x or y values in scatter plots
 * and sets the values of these points to null.
 * Additionally it finds and sets the minimum of the y-axis.
 *
 * @param {Array} series
 * @param {Object} options
 * @return {Array} series with null values
 */
ScatterPlotChart.prototype.processData = function (series, options) {
    var yMin = Number.MAX_VALUE;

    for (var i = 0; i < series.length; i++) {

        // remove undefined values
        series[i].data = series[i].data.filter(function (point) {
            return typeof point.x !== 'undefined' && typeof point.y !== 'undefined';
        });

        // find minimum
        for (var j = 0; j < series[i].data.length; j++) {
            if (yMin > series[ i ].data[ j ].y) {
                yMin = series[ i ].data[ j ].y;
            }
        }
    }

    options.yAxis.min = yMin - 1;

    return series;
};

//create a jquery plugin
$.fn.scatterPlotChart = function () {
    return this.each(function () {
        var chart;
        var $closestInvestmentfondsPropertyGroup = $(this).closest('.investmentfonds-property-group');
        var self = this;
        /**
         * init chart on desktop devices
         */
        var initDesktop = function () {
            $closestInvestmentfondsPropertyGroup.show();

            chart = new ScatterPlotChart(self);
            chart.draw(chart.newOptions);
        };

        if (!hasRwdSwitch()) {
            initDesktop();
            return;
        } // else

        // media queries
        enquire.register('screen and (max-width: 767px)', {
            match: function () {
                $closestInvestmentfondsPropertyGroup.hide();

                if (chart) {
                    chart.destroy();
                }
            }
        });

        enquire.register('screen and (min-width: 768px)', {
            match: initDesktop
        });
    });
};

//call for each chart
$(function () {
    $('.chart[data-type="scatter"]').scatterPlotChart();
});
