/**
 * This is the class for a product price chart using Highcharts. It extends the basic Chart class.
 * After creating an instance the draw function must be called.
 *
 * @class ProductPriceChart
 * @constructor
 * @extends Chart
 * @param {Object} chart
 */
window.ProductPriceChart = function (chart) {
    // super
    Chart.call(this, chart);

    /**
     * @override for there's a type per series and not for the whole chart
     * @type object
     */
    this.defaultOptions = {
        isHighStock: false,
        tooltip: {
            xDateFormat: '%A, %d.%m.%Y'
        }
    };
};

// extend super class chart
ProductPriceChart.prototype = Object.create(Chart.prototype);
ProductPriceChart.prototype.constructor = ProductPriceChart;

// create a jquery plugin
$.fn.productPriceChart = function () {
    return this.each(function () {
        new ProductPriceChart(this).draw();
    });
};

// call for each chart
$(function () {
    $('.chart[data-type="arearange"]').productPriceChart();
});
