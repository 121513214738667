/**
 * This is the class for a fonds performance chart using Highcharts.
 * It extends the basic Chart class with some defined calculations.
 * After creating an instance the draw function must be called.
 *
 * @class FondsPerformanceChart
 * @constructor
 * @extends Chart
 * @param {Object} chartContainer
 */
window.FondsDrawdownChart = function (chartContainer) {
    //call super constructor
    Chart.call(this, chartContainer);
    //reference
    var _this = this;

    /**
     * debounces the tracking of the navigator
     * @type {Function}
     */
    var trackNavigator = $.debounce(function () {
        window.Webtracking.send('fonds.einzelprodukt.grafik.verlust.zoomkalender');
    }, 300);

    // track user on selecting a date via datepicker using the custom event "onSelectDate"
    $(chartContainer).on('onSelectDate', function () {
        window.Webtracking.send('fonds.einzelprodukt.grafik.verlust.kalender');
    });

    //specific options
    this.newOptions = {
        tooltip: {
            pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y:,.2f}%</b><br/>',
            shared: true,
            split: false
        },
        chart: {
            type: 'area',
            events: {
                load: function () {
                    //redraw chart for scaling
                    _this.recalcDrawdown(this, true);
                    _this.addDatePicker(this);
                    this.redraw();
                }
            },
            panning: false,
            zoomType: false,
            pinchType: false //BEPRO-7983: scroll on mobile (iOS) http://api.highcharts.com/highcharts#chart.pinchType
        },
        colors: ['#7cb5ec', '#434348'],
        xAxis: {
            events: {
                afterSetExtremes: function (e) {
                    if(e.trigger === 'navigator' || e.trigger === 'rangeSelectorButton' || e.trigger === 'rangeSelectorInput') {
                        _this.recalcDrawdown(e.target.chart);
                        e.target.chart.redraw();
                    }

                    // web tracking
                    if (e.trigger === 'navigator') {
                        trackNavigator();
                    }

                    if (e.trigger === 'rangeSelectorButton') {
                        window.Webtracking.send('fonds.einzelprodukt.grafik.verlust.zoomkalender');
                    }
                }
            },
            minRange: 31 * 24 * 60 * 60 * 1000 // one month [ms]
        },
        yAxis: {
            offset: 35,
            labels: {
              formatter: function() {
                  if (this.value !== 0) { // Bepro-9234
                      return this.value + '%';
                  }
              }
            },
            max: 0
        },
        rangeSelector: {
            inputBoxBorderColor: '#ccc',
            inputBoxWidth: 94,
            inputStyle: {
                color: '#333'
            },
            buttons: [
                {
                    type: 'year',
                    count: 1,
                    text: '1 Jahr'
                },
                {
                    type: 'year',
                    count: 3,
                    text: '3 Jahre'
                },
                {
                    type: 'year',
                    count: 5,
                    text: '5 Jahre'
                }
            ],
            buttonTheme: {
                width: 60
            },
            selected: 0,
            inputEnabled: false
        },
        isHighStock: true,
        scrollbar: {
            enabled: false
        },
        navigator: {
            enabled: false
        },
        plotOptions: {
            series: {
                tooltip: {
                    followTouchMove: false
                }
            }
        }
    };

    this.desktopOptions = $.extend(true, {}, this.newOptions, {
        navigator: {
            enabled: true,
            adaptToUpdatedData: false // else: the button "10 Jahre" doesn't work
        },
        rangeSelector: {
            buttons: [
                {}, {}, {},
                {
                    type: 'year',
                    count: 10,
                    text: '10 Jahre'
                }
            ],
            selected: 2,
            inputEnabled: true
        }
    });
};

//extend super class chart
FondsDrawdownChart.prototype = Object.create(Chart.prototype);
FondsDrawdownChart.prototype.constructor = FondsDrawdownChart;

FondsDrawdownChart.prototype.draw = function (options) {
    var _this = this;
    //call inherit getData() function
    this.getData(function (data) {

        _this.prependDate(data);
        _this.cashedData = $.extend(true, [], data.series);
        _this.extractDates(data);
        _this.addSeriesBeginningLines(options, data);

        //call super draw() function
        Chart.prototype.draw.call(_this, options, data);
    });
    return this;
};

/**
 * add a plot line to the beginning of each serie
 * @param: chart
 */
FondsDrawdownChart.prototype.addSeriesBeginningLines = function (options, data) {
    var plotLines = [];
    for (var i = 0; i < data.series.length; i++) {
        //replace label
        var label = data.series[i].name.indexOf("Referenzindex") >= 0 ? "Start Index": "Start Fonds";
        //define plot line
        plotLines.push({
            color: options.colors[i],
            width: 1,
            dashStyle: 'Dash',
            label: {
              text: label,
              verticalAlign: 'bottom',
              y: -80,
              style: {
                color: options.colors[i]
              }
            },
            value: data.series[i].data[0][0]
        })
    };
    //add plot liens to options
    options.xAxis.plotLines = plotLines;
};

/**
 * calculates drawdown based on chart
 * @param: chart
 */
FondsDrawdownChart.prototype.recalcDrawdown = function (chart, isFirstLoad) {
    this.recalcWealth(chart, isFirstLoad);

    var seriesLength = chart.series.length;

    for (var i = 0; i < seriesLength; i++) {

        var serie = chart.series[ i ];
        var isNavigator = serie.name.indexOf('Navigator') >= 0;

        // skip navigator after the initial load
        if (isNavigator && !isFirstLoad) {
            continue;
        }

        var max = Number.MIN_VALUE;
        //in navigator cropEnd is not defined
        var end = serie.cropEnd ? serie.cropEnd : serie.data.length;

        for (var j = serie.cropStart; j < end; j++) {
            // if point isn't visible
            if (serie.data[ j ].plotX < 0) {
                //set invisible points to "0" -> otherwise we could get ugly effects, see "DE0009800003"
                serie.data[ j ].update(0);
                continue;
            }

            // find new maximum
            if (max < serie.data[ j ].y) {
                max = serie.data[ j ].y
            }

            // need to multiply by 100 here, unit is [%]
            serie.data[ j ].update((serie.data[ j ].y / max - 1) * 100, false);
        }

        // case: the series belongs to the fond and not the reference index (Noxum sadly can't send us a flag, but the
        // fond will always come first)
        if (i === 0) {
            // update plot line
            chart.yAxis[ 0 ].removePlotLine('minValue');
            chart.yAxis[ 0 ].addPlotLine({
                id: 'minValue',
                value: this.getMinValue(serie),
                color: '#FF0000',
                width: 1,
                dashStyle: 'dash'
            });
        }
    }
};

/**
 * returns the minimun value in serie.data
 *
 * @param {object} serie
 * @return {Number} minimum value
 */
FondsDrawdownChart.prototype.getMinValue = function (serie) {
    var min = 0,
        data = serie.data,
        end = serie.cropEnd ? serie.cropEnd : serie.data.length,
        start = serie.cropStart ? serie.cropStart : 0;

    for (var i = start; i < end; i++) {
        if (data[ i ].y < min) {
            min = data[ i ].y;
        }
    }

    return min;
};

//create a jquery plugin
$.fn.fondsDrawdownChart = function () {
    return this.each(function () {
        var chart;
        var self = this;
        /**
         * init chart on desktop devices
         */
        var initDesktop = function () {
            chart = new FondsDrawdownChart(self);
            chart.draw(chart.desktopOptions);
        };

        if (!hasRwdSwitch()) {
            initDesktop();
            return;
        } // else

        // media queries
        enquire.register('screen and (max-width: 767px)', {
            match: function () {
                if (chart) {
                    chart.destroy();
                }

                $(self)
                    .append('<a class="chart__open-chart btn btn-primary" href="#"><i class="icon icon-chart"></i>Chart anzeigen</a>')
                    .find('.chart__open-chart')
                    .click(function (e) {
                        e.preventDefault();
                        chart = new FondsDrawdownChart(self);
                        chart.draw(chart.newOptions);
                    });
            }
        });

        enquire.register('screen and (min-width: 768px)', {
            match: initDesktop
        });
    });
};

//call for each chart
$(function () {
    $('.chart[data-type="drawdown"]').fondsDrawdownChart();
});
